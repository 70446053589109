import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { FunctionComponent, useEffect } from 'react';

import { useFormik } from 'formik';

import { schema, ValidationCode } from './schema';
import NominalContainer from 'src/components/common/NominalContainer';
import useStyles from './styles';

type SecurityCodeFormProps = {
  isLoading: boolean;
  errorMessage?: string;
  remainingAttempts?: number;
  accessToken?: string;
  onSubmit: (validation: ValidationCode) => void;
  onRequestCode: () => void;
};

const SecurityCodeForm: FunctionComponent<SecurityCodeFormProps> = ({
  isLoading = false,
  errorMessage,
  remainingAttempts,
  onSubmit,
  onRequestCode,
}) => {
  const classes = useStyles();

  useEffect(() => {
    onRequestCode();
  }, [onRequestCode]);

  const handleRequestSecurityCode = () => {
    onRequestCode();
  };

  const formik = useFormik({
    initialValues: {
      code: undefined,
    },
    validationSchema: schema,
    onSubmit,
  });

  return (
    <NominalContainer title={'Identification à double facteurs'}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.inputContainer}>
          <div className={classes.inputWrapper}>
            <TextField
              variant={'outlined'}
              color={'primary'}
              hiddenLabel
              fullWidth
              id="code"
              name="code"
              value={formik.values.code}
              onChange={formik.handleChange}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
              inputProps={{ min: 4, max: 4, maxLength: 4, inputMode: 'numeric' }}
              InputProps={{
                classes: {
                  input: classes.inputStyles,
                },
              }}
            />
            <div className={classes.errorMessage}>
              {errorMessage && (
                <Typography variant={'body1'} color={'error'}>
                  {errorMessage}
                </Typography>
              )}
              {remainingAttempts && (
                <Typography variant={'body1'} color={'error'}>
                  Tentatives restantes: {remainingAttempts}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <div className={classes.processDetail}>
          <Typography color={'primary'} variant={'body1'}>
            Un message contenant un code de validation vous a été envoyé par SMS. Entrez le code
            pour continuer.
          </Typography>
        </div>
        <div className={classes.askCodeValidationContainer}>
          <Typography color={'primary'} variant={'body1'} className={classes.askCodeValidation}>
            Vous n'avez pas reçu le code de validation ?
          </Typography>
          <Button variant={'text'} onClick={handleRequestSecurityCode} disabled={isLoading}>
            <Typography
              color={'primary'}
              variant={'h5'}
              className={classes.askCodeValidationAction}
            >
              Renvoyer le code
            </Typography>
          </Button>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            color={'primary'}
            fullWidth
            size={'large'}
            variant={'contained'}
            type={'submit'}
            disabled={isLoading || formik.values.code === undefined}
          >
            {isLoading && <CircularProgress size={24} />}
            {!isLoading && 'Valider'}
          </Button>
        </div>
      </form>
    </NominalContainer>
  );
};

export default SecurityCodeForm;
