import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { LoginError } from 'src/domains/authentication/types';
import { NavigationRoute } from 'src/routes/navigationRoute';
import authenticationService from 'src/services/authentication';
import useLogin from '../../domains/authentication/hooks/useLogin';
import { LoginPassword } from './CredentialsForm/schema';
import {
  useRequestSecurityCodeMutation,
  useVerifySecurityCodeMutation,
} from 'src/domains/authentication/api';
import { ValidationCode } from 'src/components/authentication/SecurityCodeForm/schema';
import { useDispatch } from 'react-redux';
import { userApi } from 'src/domains/user/repository';

interface LocationState {
  from: {
    pathname: string;
  };
}

const useInvalidateCurentUser = () => {
  const dispatch = useDispatch();

  const invalidateCurrentUser = useCallback(() => {
    dispatch(userApi.util.invalidateTags(['User']));
  }, [dispatch]);

  return { invalidateCurrentUser };
};

export enum AuthenticationStep {
  AUTHENTICATION_BLOCKED = 'AUTHENTICATION_BLOCKED',
  DEFAULT = 'DEFAULT',
  SECURITY_CODE = 'SECURITY_CODE',
  TRUST_BROWSER = 'TRUST_BROWSER',
  HOME = 'HOME',
}

const useAuthenticate = () => {
  const [login, { loginResult, isLoading, isSuccess, isError, error }] = useLogin();

  const { invalidateCurrentUser } = useInvalidateCurentUser();
  const [
    verifySecurityCode,
    {
      isLoading: isCodeVerifLoading,
      isError: isCodeVerifError,
      error: codeVerifError,
      isSuccess: isCodeVerifSuccess,
    },
  ] = useVerifySecurityCodeMutation();

  const [requestCode, { isError: isRequestCodeError, error: requestCodeError }] =
    useRequestSecurityCodeMutation();

  const history = useHistory<LocationState>();
  const location = useLocation<LocationState>();

  const [is2FAEnabled, setIs2FAEnabled] = useState<boolean>(false);
  const [authenticationStep, setAuthenticationStep] = useState(AuthenticationStep.DEFAULT);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [credentials, setCredentials] = useState<LoginPassword | undefined>();

  const isTrustBrowserEnabled: boolean = useMemo(() => !!authenticationService.getBTrustMe(), []);
  const [isAuthenticationBlocked, setIsAuthenticationBlocked] = useState<boolean>(
    !!authenticationService.getRetryAfter(),
  );

  const loginError = useMemo<LoginError | undefined>(() => {
    if (isError && error) {
      if ('data' in error) {
        return error.data as LoginError;
      }
    }
    return undefined;
  }, [error, isError]);

  const verifyCodeError = useMemo<LoginError | undefined>(() => {
    if (isCodeVerifError && codeVerifError) {
      if ('data' in codeVerifError) {
        return codeVerifError.data as LoginError;
      }
    }
    return undefined;
  }, [codeVerifError, isCodeVerifError]);

  const codeRequestError = useMemo<LoginError | undefined>(() => {
    if (isRequestCodeError && requestCodeError) {
      if ('data' in requestCodeError) {
        return requestCodeError.data as LoginError;
      }
    }
    return undefined;
  }, [requestCodeError, isRequestCodeError]);

  const onCredentialFormSubmit = useCallback(
    (formValues: LoginPassword) => {
      setCredentials(formValues);
      setErrorMessage('');
      login({ ...formValues });
    },
    [login],
  );

  const onRequestCode = useCallback(() => {
    setErrorMessage('');
    requestCode();
  }, [requestCode]);

  const onVerificationCodeSubmit = useCallback(
    (validation: ValidationCode) => {
      if (validation && validation.code) verifySecurityCode(validation.code);
    },
    [verifySecurityCode],
  );

  const onAuthenticationBlockedEnd = useCallback(() => {
    authenticationService.clearRetryAfter();
    setIsAuthenticationBlocked(false);
  }, []);

  const onTrustBrowserEnd = useCallback(
    () => setAuthenticationStep(AuthenticationStep.HOME),
    [setAuthenticationStep],
  );

  useEffect(() => {
    if (loginResult && loginResult.result === 'REQUIRE_VERIFICATION') {
      setIs2FAEnabled(true);
      setAuthenticationStep(AuthenticationStep.SECURITY_CODE);
    }
  }, [loginResult]);

  useEffect(() => {
    if (loginError) {
      const errorCode = loginError.code;

      if (errorCode === 'authentication.failure') {
        setErrorMessage('Identifiant ou mot de passe incorrect');
      }
    }
  }, [loginError, setAuthenticationStep]);

  useEffect(() => {
    if (!isCodeVerifLoading) {
      if (!isCodeVerifError && isCodeVerifSuccess) {
        invalidateCurrentUser();
      }
    }
  }, [isCodeVerifLoading, isCodeVerifSuccess, isCodeVerifError, invalidateCurrentUser]);

  useEffect(() => {
    if (verifyCodeError) {
      const errorCode = verifyCodeError.code;

      if (errorCode === 'doubleAuthent.invalidCode') {
        setErrorMessage('Code de validation incorrect');
      }
    }
  }, [verifyCodeError, setErrorMessage]);

  useEffect(() => {
    if (codeRequestError) {
      const errorCode = codeRequestError.code;

      if (errorCode === 'doubleAuthent.tooManyRequest') {
        setErrorMessage('Trop de demandes simultanées, veuillez réessayer ultérieurement');
      }
    }
  }, [codeRequestError, setErrorMessage]);

  useEffect(() => {
    if (!isLoading && !isError && isSuccess) {
      if (is2FAEnabled && !isTrustBrowserEnabled) {
        setAuthenticationStep(AuthenticationStep.TRUST_BROWSER);
      } else {
        setAuthenticationStep(AuthenticationStep.HOME);
      }
    }
  }, [isLoading, isSuccess, isError, is2FAEnabled, isTrustBrowserEnabled, setAuthenticationStep]);

  useEffect(() => {
    if (isAuthenticationBlocked) {
      setAuthenticationStep(AuthenticationStep.AUTHENTICATION_BLOCKED);
    }
  }, [isAuthenticationBlocked, setAuthenticationStep]);

  useEffect(() => {
    if (
      !isAuthenticationBlocked &&
      authenticationStep === AuthenticationStep.AUTHENTICATION_BLOCKED
    ) {
      setAuthenticationStep(AuthenticationStep.DEFAULT);
    }
  }, [authenticationStep, isAuthenticationBlocked, setAuthenticationStep]);

  useEffect(() => {
    if (authenticationStep === AuthenticationStep.HOME) {
      const { from } = location.state || { from: { pathname: NavigationRoute.HOME } };
      history.replace(from);
    }
  }, [authenticationStep, history, location.state]);

  return useMemo(
    () => ({
      credentials,
      errorMessage,
      isLoading,
      isSuccess,
      isError,
      login,
      authenticationStep,
      onAuthenticationBlockedEnd,
      onCredentialFormSubmit,
      onTrustBrowserEnd,
      onVerificationCodeSubmit,
      onRequestCode,
    }),
    [
      authenticationStep,
      credentials,
      errorMessage,
      isError,
      isLoading,
      isSuccess,
      login,
      onAuthenticationBlockedEnd,
      onCredentialFormSubmit,
      onTrustBrowserEnd,
      onVerificationCodeSubmit,
      onRequestCode,
    ],
  );
};

export default useAuthenticate;
