import { createAction } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { userAdminHttpClient } from 'src/services/user-admin-http-client';
import { Credentials } from './types';

export const CLEAR_STATE = 'RA/CLEAR_STATE';
export const clearState = createAction('ROOT/CLEAR_STATE');

export interface ResetPasswordRequestDTO {
  email: string;
}

export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: userAdminHttpClient,
  endpoints: builder => ({
    login: builder.mutation<any, Credentials>({
      query: ({ login, password }) => {
        const formUrlEncoded = new URLSearchParams();
        formUrlEncoded.append('username', login);
        formUrlEncoded.append('password', password);

        return {
          url: '/api/v1/auth/login',
          method: 'POST',
          headers: [['Content-Type', 'application/x-www-form-urlencoded']],
          body: formUrlEncoded,
        };
      },
    }),
    requestSecurityCode: builder.mutation<any, void>({
      query: () => ({
        url: '/api/v1/auth/verification-codes',
        method: 'GET',
      }),
    }),
    verifySecurityCode: builder.mutation<any, number>({
      query: code => ({
        url: '/api/v1/auth/verification-codes/verify',
        method: 'POST',
        body: { code },
      }),
    }),
    trustMe: builder.mutation<void, void>({
      query: () => ({
        url: '/api/v1/auth/trust-me',
        method: 'POST',
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/api/v1/auth/logout',
        method: 'POST',
      }),
    }),
    validateTemporaryAccessToken: builder.mutation<void, string>({
      query: token => ({
        url: `/v1/temporary-access-tokens/${token}/validate`,
        method: 'POST',
        params: {
          token,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useTrustMeMutation,
  useRequestSecurityCodeMutation,
  useValidateTemporaryAccessTokenMutation,
  useVerifySecurityCodeMutation,
} = authenticationApi;

export default authenticationApi;
