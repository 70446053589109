import { useEffect, useState } from 'react';

import { Credentials } from '../../types';
import { useLoginMutation } from '../../api';
import useGetConnectedUser from '../../../user/hooks/useGetConnectedUser';

export type UseLoginValue = [
  (arg: Credentials) => void | any,
  {
    loginResult: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    error: any;
  },
];

const useLogin = (): UseLoginValue => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);

  const [
    login,
    {
      data: loginResult,
      isLoading: isLoginLoading,
      isSuccess: isLoginSuccess,
      isError: isLoginError,
      error: loginError,
    },
  ] = useLoginMutation();

  const {
    isLoading: isGetConnectedUserLoading,
    isSuccess: isGetConnectedUserSuccess,
    isError: isGetConnectedUserError,
    error: getConnectedUserError,
  } = useGetConnectedUser(!isLoginError && !isLoginLoading && isLoginSuccess);

  useEffect(() => {
    setIsLoading(isLoginLoading || isGetConnectedUserLoading);
    setIsError(isLoginError || isGetConnectedUserError);
    setIsSuccess(isLoginSuccess || isGetConnectedUserSuccess);
    setError(loginError || getConnectedUserError);
  }, [
    setIsLoading,
    isLoginLoading,
    isGetConnectedUserLoading,
    setIsError,
    isLoginError,
    isGetConnectedUserError,
    setIsSuccess,
    isLoginSuccess,
    isGetConnectedUserSuccess,
    error,
    setError,
    loginError,
    getConnectedUserError,
  ]);

  return [login, { loginResult, isLoading, isSuccess, isError, error }];
};

export default useLogin;
